import { isDefined } from "@clipboard-health/util-ts";
import { useRequiresBreakPolicyAcknowledgement } from "@src/appV2/redesign/Shift/Booking/useRequiresBreakPolicyAcknowledgement";
import { MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS } from "@src/appV2/ShiftBlocks/utils";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { useGetShiftState } from "@src/appV2/Shifts/Shift/ShiftState/api/useGetShiftState";
import { isSolveUnpaidBreaksConfigEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";
import { differenceInMinutes, parseISO } from "date-fns";

import { useGetWorkerShift } from "../../api/useGetWorkerShift";

export function useGetShiftDetailsData(params: { shiftId: string }) {
  const { shiftId } = params;

  const isShiftEndpointEnabled = isDefined(shiftId) && shiftId.length > 0;
  const {
    data: shiftData,
    isLoading: isShiftLoading,
    error: shiftError,
    refetch: refetchShift,
  } = useGetWorkerShift(shiftId ?? "", {
    enabled: isShiftEndpointEnabled,
  });

  const shift = shiftData?.response;
  const workplaceId = shift?.facilityId;

  const {
    data: shiftState,
    error: shiftStateError,
    isLoading: isShiftStateLoading,
  } = useGetShiftState(
    { shiftStateDependencies: { _id: shiftId } },
    {
      enabled: isShiftEndpointEnabled,
    }
  );

  const solveUnpaidBreaksConfig =
    shiftState?.metadata.solveUnpaidBreaksRolloutStageWithTargetingConfig;
  const isSolveUnpaidBreaksFeatureEnabled =
    isSolveUnpaidBreaksConfigEnabled(solveUnpaidBreaksConfig);
  const doesFacilityRequireMandatoryBreak = shiftState?.metadata.requiresLunchBreak ?? false;

  const isBreakPolicyAcknowledgementRequired =
    isDefined(shift) &&
    isSolveUnpaidBreaksFeatureEnabled &&
    doesFacilityRequireMandatoryBreak &&
    differenceInMinutes(parseISO(shift.end), parseISO(shift.start)) >
      MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS * 60;

  const {
    requiresBreakPolicyAcknowledgement,
    isLoading: isPolicyAcknowledgementLoading,
    breakPolicyNoteId,
  } = useRequiresBreakPolicyAcknowledgement({
    action: NoteAcknowledgementAction.CLOCK_IN,
    workplaceId: workplaceId ?? "",
    enabled: isDefined(workplaceId) && isBreakPolicyAcknowledgementRequired,
  });

  return {
    shift,
    shiftState,
    shiftError: shiftError || shiftStateError,
    breakPolicyNoteId,
    requiresBreakPolicyAcknowledgement,
    isLoading: isShiftLoading || isPolicyAcknowledgementLoading || isShiftStateLoading,
    refetchShift,
  };
}
